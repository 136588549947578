import React from 'react';
import { Box, Text } from 'rebass';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@reach/tabs';
import '@reach/tabs/styles.css';

import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import { theme } from '../../../../layouts/main.layout';
import { VozziPrimaryButton } from '../../../../components/layout/_/button.component';
import { ResponsiveContainer } from '../../../../components/functional/ResponsiveContainer.functional';
import { LanguageGuard } from '../../../../components/layout/LanguageGuard/LanguageGuard';
import { accordionItem, faq } from './css/faq.css';

export const Faq: React.FC = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      id: 1,
      question: (
        <>
          {t('ra_FaqQuestionOne')} <span className="arrow"></span>
        </>
      ),
      answer: (
        <>
          <Text>{t('ra_FaqAnswerOne')}</Text>
          <LanguageGuard visible={[{ country: 'rs' }, { country: 'me' }, { country: 'mk' }]}>
            <Link to={t('link_webShop')}>
              <VozziPrimaryButton mt={3}>{t('btn_SeePackages')}</VozziPrimaryButton>
            </Link>
          </LanguageGuard>
        </>
      ),
    },
    {
      id: 2,
      question: (
        <>
          {t('ra_FaqQuestionTwo')} <span className="arrow"></span>
        </>
      ),
      answer: (
        <>
          <Text>{t('ra_FaqAnswerTwo')}</Text>
          <LanguageGuard hidden={[{ country: 'eu' }, { country: 'ro' }]}>
            <Link to={t('link_webShop')}>
              <VozziPrimaryButton mt={3}>{t('btn_SeePackages')}</VozziPrimaryButton>
            </Link>
          </LanguageGuard>
        </>
      ),
    },
    {
      id: 3,
      question: (
        <>
          {t('ra_FaqQuestionThree')} <span className="arrow"></span>
        </>
      ),
      answer: (
        <>
          <Text>{t('ra_FaqAnswerThree')}</Text>
          <Link to={`${t('link_home')}#${t('link_homeDiscount_section_id')}`}>
            <VozziPrimaryButton mt={3}>{t('btn_SeeMore')}</VozziPrimaryButton>
          </Link>
        </>
      ),
    },
  ];
  return (
    <PageSection extendStyles={faq} fullWidth backgroundColor={theme.colors.white} paddingTop={1} paddingBottom={80}>
      <SectionHeader title={t('ra_FaqHeading')} mb={5} />

      <ResponsiveContainer breakpoint="md">
        {(matches: any): JSX.Element => (
          <>
            {matches.mobile && (
              <Accordion>
                {faqs.map((faq) => {
                  return (
                    <AccordionItem key={faq.id} css={accordionItem}>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h4>{faq.question}</h4>
                        </AccordionItemButton>
                      </AccordionItemHeading>

                      <AccordionItemPanel>{faq.answer}</AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            )}
            {matches.desktop && (
              <Tabs defaultIndex={0}>
                <TabList>
                  {faqs.map((faq) => {
                    return <Tab key={faq.id}>{faq.question}</Tab>;
                  })}
                </TabList>

                <Box>
                  <TabPanels>
                    {faqs.map((faq) => {
                      return <TabPanel key={faq.id}>{faq.answer}</TabPanel>;
                    })}
                  </TabPanels>
                </Box>
              </Tabs>
            )}
          </>
        )}
      </ResponsiveContainer>
    </PageSection>
  );
};
