export const allSerbiaCities = [
  { title: 'Beograd' },
  { title: 'Beograd Vozdovac' },
  { title: 'Beograd Čukarica' },
  { title: 'Beograd Zvezdara' },
  { title: 'Beograd Palilula' },
  { title: 'Novi Beograd' },
  { title: 'Beograd Zemun' },
  { title: 'Beograd Rakovica' },
  { title: 'Kaluđerica' },
  { title: 'Rušanj' },
  { title: 'Borča' },
  { title: 'Ovča' },
  { title: 'Padinska Skela' },
  { title: 'Beli Potok' },
  { title: 'Vrčin' },
  { title: 'Zuce' },
  { title: 'Pinosava' },
  { title: 'Ripanj' },
  { title: 'Ralja' },
  { title: 'Mali Požarevac' },
  { title: 'Ostružnica' },
  { title: 'Sremčica' },
  { title: 'Umka' },
  { title: 'Mala Moštanica' },
  { title: 'Velika Mostanica' },
  { title: 'Surčin' },
  { title: 'Dobanovci' },
  { title: 'Boljevci' },
  { title: 'Jakovo' },
  { title: 'Ugrinovci' },
  { title: 'Bečmen' },
  { title: 'Progar' },
  { title: 'Petrovcic' },
  { title: 'Smederevo' },
  { title: 'Grocka' },
  { title: 'Boleč' },
  { title: 'Begaljica' },
  { title: 'Leštane' },
  { title: 'Lipe' },
  { title: 'Radinac' },
  { title: 'Mihajlovac' },
  { title: 'Mala Krsna' },
  { title: 'Osipaonica' },
  { title: 'Saraorci' },
  { title: 'Golobok' },
  { title: 'Lozovik' },
  { title: 'Miloševac' },
  { title: 'Krnjevo' },
  { title: 'Velika Plana' },
  { title: 'Lugavcina' },
  { title: 'Skobalj' },
  { title: 'Veliko Orašje' },
  { title: 'Staro Selo' },
  { title: 'Markovac' },
  { title: 'Donja Livadica' },
  { title: 'Rakinac' },
  { title: 'Vodanj' },
  { title: 'Vranovo' },
  { title: 'Vinča' },
  { title: 'Mladenovac' },
  { title: 'Vlaška' },
  { title: 'Selevac' },
  { title: 'Velika Krsna' },
  { title: 'Kovačevac' },
  { title: 'Ratari' },
  { title: 'Jagnjilo' },
  { title: 'Pružatovac' },
  { title: 'Velika Ivanča' },
  { title: 'Koraćica' },
  { title: 'Smederevska Palanka' },
  { title: 'Azanja' },
  { title: 'Banicina' },
  { title: 'Kusadak' },
  { title: 'Meljak' },
  { title: 'Vranić' },
  { title: 'Umčari' },
  { title: 'Kolari' },
  { title: 'Drugovac' },
  { title: 'Šepšin' },
  { title: 'Sopot' },
  { title: 'Rogača' },
  { title: 'Sibnica' },
  { title: 'Barajevo' },
  { title: 'Beljina' },
  { title: 'Veliki Borak' },
  { title: 'Obrenovac' },
  { title: 'Barič' },
  { title: 'Zabrežje' },
  { title: 'Draževac' },
  { title: 'Stubline' },
  { title: 'Grabovac' },
  { title: 'Skela' },
  { title: 'Lazarevac' },
  { title: 'Vreoci' },
  { title: 'Dudovica' },
  { title: 'Junkovac' },
  { title: 'Veliki Crljeni' },
  { title: 'Stepojevac' },
  { title: 'Barosevac' },
  { title: 'Rudovci' },
  { title: 'Mirosaljci' },
  { title: 'Požarevac' },
  { title: 'Brezane' },
  { title: 'Bradarac' },
  { title: 'Dubravica' },
  { title: 'Kostolac' },
  { title: 'Kličevac' },
  { title: 'Veliko Gradište' },
  { title: 'Majilovac' },
  { title: 'Braničevo' },
  { title: 'Golubac' },
  { title: 'Dobra' },
  { title: 'Bratinac' },
  { title: 'Topolovnik' },
  { title: 'Vinci' },
  { title: 'Brnjica' },
  { title: 'Kučevo' },
  { title: 'Neresnica' },
  { title: 'Srednjevo' },
  { title: 'Rabrovo' },
  { title: 'Duboka' },
  { title: 'Voluja' },
  { title: 'Klenje' },
  { title: 'Petrovac Na Mlavi' },
  { title: 'Ranovac' },
  { title: 'Melnica' },
  { title: 'Veliko Laole' },
  { title: 'Burovac' },
  { title: 'Šetonje' },
  { title: 'Malo Crniće' },
  { title: 'Smoljinac' },
  { title: 'Bozevac' },
  { title: 'Veliko Selo' },
  { title: 'Rašanac' },
  { title: 'Krepoljin' },
  { title: 'Osanica' },
  { title: 'Vukovac' },
  { title: 'Žagubica' },
  { title: 'Laznica' },
  { title: 'Suvi Do' },
  { title: 'Aleksandrovac' },
  { title: 'Vlaški Do' },
  { title: 'Poljana' },
  { title: 'Simićevo' },
  { title: 'Žabari' },
  { title: 'Porodin' },
  { title: 'Valjevo' },
  { title: 'Brankovina' },
  { title: 'Rajković' },
  { title: 'Dračić' },
  { title: 'Divčibare' },
  { title: 'Lelić' },
  { title: 'Poćuta' },
  { title: 'Pećka' },
  { title: 'Ub' },
  { title: 'Radljevo' },
  { title: 'Brgule' },
  { title: 'Pambukovica' },
  { title: 'Banjani' },
  { title: 'Popučke' },
  { title: 'Divci' },
  { title: 'Slovac' },
  { title: 'Lajkovac' },
  { title: 'Bogovadja' },
  { title: 'Jabučje' },
  { title: 'Ljig' },
  { title: 'Mionica' },
  { title: 'Gornja Toplica' },
  { title: 'Brezde' },
  { title: 'Slavkovica' },
  { title: 'Belanovica' },
  { title: 'Pricevic' },
  { title: 'Kamenica' },
  { title: 'Osečina' },
  { title: 'Komirić' },
  { title: 'Stave' },
  { title: 'Šabac' },
  { title: 'Mačvanski Pričinović' },
  { title: 'Drenovac' },
  { title: 'Orid' },
  { title: 'Debeljača' },
  { title: 'Provo' },
  { title: 'Koceljeva' },
  { title: 'Svileuva' },
  { title: 'Kamenica' },
  { title: 'Cerovac' },
  { title: 'Vladimirci' },
  { title: 'Draginje' },
  { title: 'Donje Crniljevo' },
  { title: 'Varna' },
  { title: 'Metlić' },
  { title: 'Tekeriš' },
  { title: 'Dobrić' },
  { title: 'Loznica' },
  { title: 'Korenita' },
  { title: 'Tršić' },
  { title: 'Petlovača' },
  { title: 'Lipolist' },
  { title: 'Prnjavor Mačvanski' },
  { title: 'Lešnica' },
  { title: 'Jadranska Lešnica' },
  { title: 'Brezjak' },
  { title: 'Ribari' },
  { title: 'Draginac' },
  { title: 'Zavlaka' },
  { title: 'Bela Crkva' },
  { title: 'Krupanj' },
  { title: 'Zajaca' },
  { title: 'Banja Koviljača' },
  { title: 'Donja Borina' },
  { title: 'Mali Zvornik' },
  { title: 'Uzovnica' },
  { title: 'Ljubovija' },
  { title: 'Radalj' },
  { title: 'Velika Reka' },
  { title: 'Donja Orovica' },
  { title: 'Drlace' },
  { title: 'Bogatić' },
  { title: 'Zminjak' },
  { title: 'Majur' },
  { title: 'Štitar' },
  { title: 'Crna Bara' },
  { title: 'Glušci' },
  { title: 'Klenje' },
  { title: 'Badovinci' },
  { title: 'Dublje' },
  { title: 'Banovo Polje' },
  { title: 'Leskovac' },
  { title: 'Manojlovce' },
  { title: 'Vučje' },
  { title: 'Miroševce' },
  { title: 'Bojnik' },
  { title: 'Kosančić' },
  { title: 'Vlasotince' },
  { title: 'Svođe' },
  { title: 'Sastav Reka' },
  { title: 'Crna Trava' },
  { title: 'Grdelica' },
  { title: 'Velika Grabovnica' },
  { title: 'Predejane' },
  { title: 'Ruplje' },
  { title: 'Lebane' },
  { title: 'Turekovac' },
  { title: 'Bosnjace' },
  { title: 'Orane' },
  { title: 'Medvedja' },
  { title: 'Gornji Brestovac' },
  { title: 'Sijarinska Banja' },
  { title: 'Tulare' },
  { title: 'Lece' },
  { title: 'Pecanjevce' },
  { title: 'Razgojna' },
  { title: 'Brestovac' },
  { title: 'Vranje' },
  { title: 'Vlase' },
  { title: 'Sveti Ilija' },
  { title: 'Vladičin Han' },
  { title: 'Priboj Vranjski' },
  { title: 'Stubal' },
  { title: 'Lepenica' },
  { title: 'Džep' },
  { title: 'Bujanovac' },
  { title: 'Ristovac' },
  { title: 'Biljaca' },
  { title: 'Preševo' },
  { title: 'Klenike' },
  { title: 'Trgovište' },
  { title: 'Donji Stajevac' },
  { title: 'Muhovac' },
  { title: 'Surdulica' },
  { title: 'Jelašnica' },
  { title: 'Vlasina Okruglica' },
  { title: 'Vlasina Rid' },
  { title: 'Vlasina Stojkovićevo' },
  { title: 'Klisura' },
  { title: 'Bozica' },
  { title: 'Gornja Lisina' },
  { title: 'Bosilegrad' },
  { title: 'Vranjska Banja' },
  { title: 'Kriva Feja' },
  { title: 'Donja Ljubata' },
  { title: 'Korbevac' },
  { title: 'Bistar' },
  { title: 'Donje Tlamino' },
  { title: 'Reljan' },
  { title: 'Oraovica' },
  { title: 'Bujanovac' },
  { title: 'Niš' },
  { title: 'Lalinac' },
  { title: 'Gornja Toponica' },
  { title: 'Gornji Matejevac' },
  { title: 'Niška Banja' },
  { title: 'Jelašnica' },
  { title: 'Malča' },
  { title: 'Guševac' },
  { title: 'Medoševac' },
  { title: 'Žitkovac' },
  { title: 'Trupale' },
  { title: 'Tešica' },
  { title: 'Gredetin' },
  { title: 'Kulina' },
  { title: 'Veliko Bonjince' },
  { title: 'Korman' },
  { title: 'Ljuberadja' },
  { title: 'Grejac' },
  { title: 'Aleksinac' },
  { title: 'Draževac(kod Aleksinca)' },
  { title: 'Rutevac' },
  { title: 'Katun' },
  { title: 'Aleksinacki Rudnik' },
  { title: 'Subotinac' },
  { title: 'Luzane' },
  { title: 'Mozgovo' },
  { title: 'Soko Banja' },
  { title: 'Čitluk' },
  { title: 'Jošanica' },
  { title: 'Šarbanovac' },
  { title: 'Dugo Polje' },
  { title: 'Gadžin Han' },
  { title: 'Gornji Barbes' },
  { title: 'Donji Dušnik' },
  { title: 'Zaplanjska Toponica' },
  { title: 'Lički Hanovi' },
  { title: 'Ravna Dubrava' },
  { title: 'Novo Selo' },
  { title: 'Mramor' },
  { title: 'Merošina' },
  { title: 'Jugbogdanovac' },
  { title: 'Donje Medurovo' },
  { title: 'Pukovac' },
  { title: 'Balajinac' },
  { title: 'Jovanovac' },
  { title: 'Popovac' },
  { title: 'Pirot' },
  { title: 'Crnokliste' },
  { title: 'Visoka Rzana' },
  { title: 'Krupač' },
  { title: 'Bela Palanka' },
  { title: 'Sićevo' },
  { title: 'Ostrovica' },
  { title: 'Crvena Reka' },
  { title: 'Dolac' },
  { title: 'Babin Kal' },
  { title: 'Dimitrovgrad' },
  { title: 'Gradina' },
  { title: 'Sukovo' },
  { title: 'Smilovci' },
  { title: 'Kamenica' },
  { title: 'Poganovo' },
  { title: 'Babusnica' },
  { title: 'Strelac' },
  { title: 'Zvonce' },
  { title: 'Temska' },
  { title: 'Svrljig' },
  { title: 'Palilula' },
  { title: 'Prekonoga' },
  { title: 'Niševac' },
  { title: 'Burdimo' },
  { title: 'Prokuplje' },
  { title: 'Velika Plana' },
  { title: 'Donja Rečica' },
  { title: 'Džigolj' },
  { title: 'Dubovo' },
  { title: 'Žitni Potok' },
  { title: 'Dobri Do' },
  { title: 'Kruševica' },
  { title: 'Doljevac' },
  { title: 'Belotinac' },
  { title: 'Žitoradja' },
  { title: 'Pejkovac' },
  { title: 'Donje Crnatovo' },
  { title: 'Malošište' },
  { title: 'Cecina' },
  { title: 'Blace' },
  { title: 'Donja Trnava' },
  { title: 'Mala Plana' },
  { title: 'Beloljin' },
  { title: 'Gornja Draguša' },
  { title: 'Barbatovac' },
  { title: 'Kuršumlija' },
  { title: 'Barlovo' },
  { title: 'Prolom' },
  { title: 'Kuršumlijska Banja' },
  { title: 'Merćez' },
  { title: 'Lukovo' },
  { title: 'Zuc' },
  { title: 'Rača' },
  { title: 'Merdare' },
  { title: 'Zaječar' },
  { title: 'Metovnica' },
  { title: 'Gradskovo' },
  { title: 'Veliki Izvor' },
  { title: 'Planinica' },
  { title: 'Lubnica' },
  { title: 'Mali Jasenovac' },
  { title: 'Bor' },
  { title: 'Donja Bela Reka' },
  { title: 'Rgotina' },
  { title: 'Zlot' },
  { title: 'Brestovačka Banja' },
  { title: 'Krivelj' },
  { title: 'Donji Milanovac' },
  { title: 'Klokočevac' },
  { title: 'Koprivnica' },
  { title: 'Salaš' },
  { title: 'Sikole' },
  { title: 'Zvezdan' },
  { title: 'Gamzigradska Banja' },
  { title: 'Borski Brestovac' },
  { title: 'Borsko Bucje' },
  { title: 'Luka' },
  { title: 'Velika Jasikova' },
  { title: 'Halovo' },
  { title: 'Majdanpek' },
  { title: 'Rudna Glava' },
  { title: 'Negotin' },
  { title: 'Štubik' },
  { title: 'Jabukovac' },
  { title: 'Urovica' },
  { title: 'Trnjane' },
  { title: 'Nikolićevo' },
  { title: 'Vražogrnac' },
  { title: 'Brusnik' },
  { title: 'Rajac' },
  { title: 'Bracevac' },
  { title: 'Kobišnica' },
  { title: 'Mokranja' },
  { title: 'Rogljevo' },
  { title: 'Kladovo' },
  { title: 'Podvrška' },
  { title: 'Mihajlovac' },
  { title: 'Brza Palanka' },
  { title: 'Slatina' },
  { title: 'Tekija' },
  { title: 'Sip' },
  { title: 'Velesnica' },
  { title: 'Korbovo' },
  { title: 'Prahovo' },
  { title: 'Radujevac' },
  { title: 'Dušanovac' },
  { title: 'Minićevo' },
  { title: 'Grljan' },
  { title: 'Grlište' },
  { title: 'Lenovac' },
  { title: 'Vratarnica' },
  { title: 'Donje Zuniće' },
  { title: 'Mali Izvor' },
  { title: 'Knjaževac' },
  { title: 'Donja Kamenica' },
  { title: 'Kalna' },
  { title: 'Podvis' },
  { title: 'Beli Potok' },
  { title: 'Vasilj' },
  { title: 'Bucje' },
  { title: 'Boljevac' },
  { title: 'Lukovo' },
  { title: 'Bogovina' },
  { title: 'Šarbanovac' },
  { title: 'Krivi Vir' },
  { title: 'Sumrakovac' },
  { title: 'Savinac' },
  { title: 'Osnić' },
  { title: 'Šarbanovac Timok' },
  { title: 'Novi Sad' },
  { title: 'Petrovaradin' },
  { title: 'Rumenka' },
  { title: 'Veternik' },
  { title: 'Sremski Karlovci' },
  { title: 'Stari Ledinci' },
  { title: 'Ledinci' },
  { title: 'Sremska Kamenica' },
  { title: 'Bukovac' },
  { title: 'Kisač' },
  { title: 'Stepanovićevo' },
  { title: 'Zmajevo' },
  { title: 'Sirig' },
  { title: 'Turija' },
  { title: 'Nadalj' },
  { title: 'Bačko Gradište' },
  { title: 'Bečej' },
  { title: 'Radičević' },
  { title: 'Bačko Petrovo Selo' },
  { title: 'Mileševo' },
  { title: 'Žabalj' },
  { title: 'Čenej' },
  { title: 'Bački Jarak' },
  { title: 'Temerin' },
  { title: 'Gospođinci' },
  { title: 'Čurug' },
  { title: 'Đurdjevo' },
  { title: 'Titel' },
  { title: 'Kać' },
  { title: 'Budisava' },
  { title: 'Kovilj' },
  { title: 'Šajkaš' },
  { title: 'Mošorin' },
  { title: 'Vilovo' },
  { title: 'Gardinovci' },
  { title: 'Lok' },
  { title: 'Rakovac' },
  { title: 'Beočin' },
  { title: 'Čerević' },
  { title: 'Banoštor' },
  { title: 'Susek' },
  { title: 'Neštin' },
  { title: 'Lug' },
  { title: 'Bačka Palanka' },
  { title: 'Futog' },
  { title: 'Begeč' },
  { title: 'Gložan' },
  { title: 'Čelarevo' },
  { title: 'Bač' },
  { title: 'Karađorđevo' },
  { title: 'Mladenovo' },
  { title: 'Obrovac' },
  { title: 'Tovariševo' },
  { title: 'Selenča' },
  { title: 'Vajska' },
  { title: 'Bodjani' },
  { title: 'Plavna' },
  { title: 'Bačko Novo Selo' },
  { title: 'Nova Gajdobra' },
  { title: 'Gajdobra' },
  { title: 'Silbaš' },
  { title: 'Parage' },
  { title: 'Vrbas' },
  { title: 'Bačko Dobro Polje' },
  { title: 'Kucura' },
  { title: 'Savino Selo' },
  { title: 'Despotovo' },
  { title: 'Pivnice' },
  { title: 'Bački Petrovac' },
  { title: 'Ravno Selo' },
  { title: 'Kulpin' },
  { title: 'Maglić' },
  { title: 'Srbobran' },
  { title: 'Sremska Mitrovica' },
  { title: 'Zasavica' },
  { title: 'Mačvanska Mitrovica' },
  { title: 'Noćaj' },
  { title: 'Salaš Noćajski' },
  { title: 'Ravnje' },
  { title: 'Radenković' },
  { title: 'Ležimir' },
  { title: 'Mandjelos' },
  { title: 'Veliki Radinci' },
  { title: 'Bešenovo' },
  { title: 'Grgurevci' },
  { title: 'Bosut' },
  { title: 'Laćarak' },
  { title: 'Martinci' },
  { title: 'Kuzmin' },
  { title: 'Kukujevci' },
  { title: 'Bacinci' },
  { title: 'Erdevik' },
  { title: 'Čalma' },
  { title: 'Divoš' },
  { title: 'Šid' },
  { title: 'Vasica' },
  { title: 'Berkasovo' },
  { title: 'Sot' },
  { title: 'Adasevci' },
  { title: 'Morović' },
  { title: 'Višnjićevo' },
  { title: 'Sremska Rača' },
  { title: 'Jamena' },
  { title: 'Ilinci' },
  { title: 'Batrovci' },
  { title: 'Bingula' },
  { title: 'Bikić Do' },
  { title: 'Ljuba' },
  { title: 'Molovin' },
  { title: 'Privina Glava' },
  { title: 'Gibarac' },
  { title: 'Stara Pazova' },
  { title: 'Banovci Dunav' },
  { title: 'Novi Banovci' },
  { title: 'Stari Banovci' },
  { title: 'Belegiš' },
  { title: 'Surduk' },
  { title: 'Golubinci' },
  { title: 'Šimanovci' },
  { title: 'Vojka' },
  { title: 'Krnješevci' },
  { title: 'Inđija' },
  { title: 'Ljukovo' },
  { title: 'Novi Karlovci' },
  { title: 'Novi Slankamen' },
  { title: 'Beška' },
  { title: 'Krčedin' },
  { title: 'Čortanovci' },
  { title: 'Maradik' },
  { title: 'Krušedol' },
  { title: 'Stari Slankamen' },
  { title: 'Nova Pazova' },
  { title: 'Ruma' },
  { title: 'Putinci' },
  { title: 'Stejanovci' },
  { title: 'Irig' },
  { title: 'Vrdnik' },
  { title: 'Jazak' },
  { title: 'Pećinci' },
  { title: 'Kraljevci' },
  { title: 'Dobrinci' },
  { title: 'Sremski Mihaljevci' },
  { title: 'Subotište' },
  { title: 'Ogar' },
  { title: 'Obrez' },
  { title: 'Asanja' },
  { title: 'Kupinovo' },
  { title: 'Platičevo' },
  { title: 'Budjanovci' },
  { title: 'Nikinci' },
  { title: 'Grabovci' },
  { title: 'Klenak' },
  { title: 'Šašinci' },
  { title: 'Hrtkovci' },
  { title: 'Popinci' },
  { title: 'Voganj' },
  { title: 'Vitojevci' },
  { title: 'Sibač' },
  { title: 'Dec' },
  { title: 'Prhovo' },
  { title: 'Karlovčić' },
  { title: 'Zrenjanin' },
  { title: 'Mihajlovo' },
  { title: 'Ečka' },
  { title: 'Stajićevo' },
  { title: 'Belo Blato' },
  { title: 'Muzlja' },
  { title: 'Aradac' },
  { title: 'Elemir' },
  { title: 'Taraš' },
  { title: 'Žitište' },
  { title: 'Klek' },
  { title: 'Ravni Topolovac' },
  { title: 'Banatski Dvor' },
  { title: 'Torda' },
  { title: 'Cestereg' },
  { title: 'Banatsko Karađorđevo' },
  { title: 'Aleksandrovo' },
  { title: 'Nova Crnja' },
  { title: 'Vojvoda Stepa' },
  { title: 'Srpska Crnja' },
  { title: 'Radojevo' },
  { title: 'Toba' },
  { title: 'Lukino Selo' },
  { title: 'Jaša Tomić' },
  { title: 'Krajišnik' },
  { title: 'Begejci' },
  { title: 'Srpski Itebej' },
  { title: 'Medja' },
  { title: 'Hetin' },
  { title: 'Novi Itebej' },
  { title: 'Banatsko Višnjićevo' },
  { title: 'Sečanj' },
  { title: 'Lazarevo' },
  { title: 'Banatski Despotovac' },
  { title: 'Botos' },
  { title: 'Sutjeska' },
  { title: 'Neuzina' },
  { title: 'Jarkovac' },
  { title: 'Banatska Dubica' },
  { title: 'Boka' },
  { title: 'Konak' },
  { title: 'Surjan' },
  { title: 'Zlatica' },
  { title: 'Perlez' },
  { title: 'Lukićevo' },
  { title: 'Tomaševac' },
  { title: 'Orlovat' },
  { title: 'Farkaždin' },
  { title: 'Knićanin' },
  { title: 'Ćenta' },
  { title: 'Melenci' },
  { title: 'Kumane' },
  { title: 'Novi Bečej' },
  { title: 'Novo Miloševo' },
  { title: 'Bocar' },
  { title: 'Kikinda' },
  { title: 'Mokrin' },
  { title: 'Nakovo' },
  { title: 'Banatsko Veliko Selo' },
  { title: 'Novi Kozarci' },
  { title: 'Rusko Selo' },
  { title: 'Banatska Topola' },
  { title: 'Basaid' },
  { title: 'Čoka' },
  { title: 'Iđos' },
  { title: 'Sajan' },
  { title: 'Padej' },
  { title: 'Ostojićevo' },
  { title: 'Jazovo' },
  { title: 'Crna Bara' },
  { title: 'Vrbica' },
  { title: 'Novi Kneževac' },
  { title: 'Sanad' },
  { title: 'Banatsko Arandjelovo' },
  { title: 'Majdan' },
  { title: 'Srpski Krstur' },
  { title: 'Đala' },
  { title: 'Subotica' },
  { title: 'Kelebija' },
  { title: 'Kelebija-granični prelaz' },
  { title: 'Bikovo' },
  { title: 'Orom' },
  { title: 'Bajmok' },
  { title: 'Mišićevo' },
  { title: 'Đurđin' },
  { title: 'Tavankut' },
  { title: 'Ljutovo' },
  { title: 'Mala Bosna' },
  { title: 'Čantavir' },
  { title: 'Višnjevac' },
  { title: 'Novi Zednik' },
  { title: 'Stari Žednik' },
  { title: 'Bačka Topola' },
  { title: 'Karadjordjevo' },
  { title: 'Mali Beograd' },
  { title: 'Njegoševo' },
  { title: 'Gunaroš' },
  { title: 'Pobeda' },
  { title: 'Mali Idjoš' },
  { title: 'Lovćenac' },
  { title: 'Feketić' },
  { title: 'Panonija' },
  { title: 'Bajša' },
  { title: 'Stara Moravica' },
  { title: 'Krivaja' },
  { title: 'Pačir' },
  { title: 'Bački Sokolac' },
  { title: 'Oresković' },
  { title: 'Novo Orahovo' },
  { title: 'Tornjoš' },
  { title: 'Senta' },
  { title: 'Gornji Breg' },
  { title: 'Kevi' },
  { title: 'Bogaras' },
  { title: 'Horgoš' },
  { title: 'Horgoš Granični Prelaz' },
  { title: 'Palić' },
  { title: 'Hajdukovo' },
  { title: 'Bački Vinogradi' },
  { title: 'Male Pijace' },
  { title: 'Martonoš' },
  { title: 'Kanjiža' },
  { title: 'Adorjan' },
  { title: 'Tresnjevac' },
  { title: 'Totovo Selo' },
  { title: 'Ada' },
  { title: 'Mol' },
  { title: 'Utrine' },
  { title: 'Sombor' },
  { title: 'Conoplja' },
  { title: 'Svetozar Miletić' },
  { title: 'Aleksa Santic' },
  { title: 'Crvenka' },
  { title: 'Kljajićevo' },
  { title: 'Telečka' },
  { title: 'Sivac' },
  { title: 'Nova Crvenka' },
  { title: 'Krusčić' },
  { title: 'Kula' },
  { title: 'Lipar' },
  { title: 'Ruski Krstur' },
  { title: 'Lalić' },
  { title: 'Stapar' },
  { title: 'Bački Brestovac' },
  { title: 'Doroslovo' },
  { title: 'Srpski Miletić' },
  { title: 'Bogojevo' },
  { title: 'Odzaci' },
  { title: 'Bački Gracac' },
  { title: 'Ratkovo' },
  { title: 'Deronje' },
  { title: 'Karavukovo' },
  { title: 'Apatin' },
  { title: 'Kupušina' },
  { title: 'Prigrevica' },
  { title: 'Sonta' },
  { title: 'Svilojevo' },
  { title: 'Bezdan' },
  { title: 'Bački Monostor' },
  { title: 'Kolut' },
  { title: 'Bački Breg' },
  { title: 'Ridjica' },
  { title: 'Gakovo' },
  { title: 'Rastina' },
  { title: 'Stanišić' },
  { title: 'Pančevo' },
  { title: 'Jabuka' },
  { title: 'Glogonj' },
  { title: 'Sefkerin' },
  { title: 'Opovo' },
  { title: 'Baranda' },
  { title: 'Sakule' },
  { title: 'Idvor' },
  { title: 'Kovačica' },
  { title: 'Kačarevo' },
  { title: 'Crepaja' },
  { title: 'Padina' },
  { title: 'Uzdin' },
  { title: 'Kovin' },
  { title: 'Bavanište' },
  { title: 'Gaj' },
  { title: 'Dubovac' },
  { title: 'Deliblato' },
  { title: 'Mramorak' },
  { title: 'Dolovo' },
  { title: 'Skorenovac' },
  { title: 'Pločica' },
  { title: 'Omoljica' },
  { title: 'Starčevo' },
  { title: 'Ivanovo' },
  { title: 'Banatski Brestovac' },
  { title: 'Vršac' },
  { title: 'Alibunar' },
  { title: 'Banatsko Novo Selo' },
  { title: 'Vladimirovac' },
  { title: 'Devojački Bunar' },
  { title: 'Banatski Karlovac' },
  { title: 'Nikolinci' },
  { title: 'Crvena Crkva' },
  { title: 'Banatska Palanka' },
  { title: 'Banatska Subotica' },
  { title: 'Dupljaja' },
  { title: 'Kajtasovo' },
  { title: 'Uljma' },
  { title: 'Ritisevo' },
  { title: 'Vlajkovac' },
  { title: 'Pavliš' },
  { title: 'Veliko Središte' },
  { title: 'Gudurica' },
  { title: 'Kuštilj' },
  { title: 'Vatin' },
  { title: 'Vojvodinci' },
  { title: 'Bela Crkva' },
  { title: 'Izbište' },
  { title: 'Straža' },
  { title: 'Jasenovo' },
  { title: 'Grebenac' },
  { title: 'Vračev Gaj' },
  { title: 'Kusić' },
  { title: 'Samoš' },
  { title: 'Seleuš' },
  { title: 'Ilandža' },
  { title: 'Novi Kozjak' },
  { title: 'Dobrica' },
  { title: 'Plandište' },
  { title: 'Lokve' },
  { title: 'Janošik' },
  { title: 'Jermenovci' },
  { title: 'Margita' },
  { title: 'Veliki Gaj' },
  { title: 'Velika Greda' },
  { title: 'Barice' },
  { title: 'Kupinik' },
  { title: 'Hajdučica' },
  { title: 'Stari Lec' },
  { title: 'Miletićevo' },
  { title: 'Kruščica' },
  { title: 'Užice' },
  { title: 'Lunovo Selo' },
  { title: 'Karan' },
  { title: 'Sevojno' },
  { title: 'Ravni' },
  { title: 'Sirogojno' },
  { title: 'Rožanstvo' },
  { title: 'Ljubiš' },
  { title: 'Požega' },
  { title: 'Ježevica' },
  { title: 'Gornja Dobrinja' },
  { title: 'Jelen Do' },
  { title: 'Arilje' },
  { title: 'Kruščica' },
  { title: 'Brekovo' },
  { title: 'Divljaka' },
  { title: 'Roge' },
  { title: 'Bioska' },
  { title: 'Kremna' },
  { title: 'Mokra Gora' },
  { title: 'Šljivovica' },
  { title: 'Bajina Bašta' },
  { title: 'Mitrovac' },
  { title: 'Zlodol' },
  { title: 'Kostojevići' },
  { title: 'Rogačica' },
  { title: 'Perućac' },
  { title: 'Kaluđerske Bare' },
  { title: 'Bačevci' },
  { title: 'Kosjerić' },
  { title: 'Seča Reka' },
  { title: 'Varda' },
  { title: 'Ražana' },
  { title: 'Prijepolje' },
  { title: 'Brodarevo' },
  { title: 'Jabuka' },
  { title: 'Aljinovici' },
  { title: 'Čajetina' },
  { title: 'Bela Zemlja' },
  { title: 'Mačkat' },
  { title: 'Gostilje' },
  { title: 'Jablanica' },
  { title: 'Zlatibor' },
  { title: 'Draglica' },
  { title: 'Kokin Brod' },
  { title: 'Jasenovo' },
  { title: 'Nova Varoš' },
  { title: 'Bozetici' },
  { title: 'Bistrica' },
  { title: 'Priboj' },
  { title: 'Sastavci' },
  { title: 'Banja Kod Priboja' },
  { title: 'Čačak' },
  { title: 'Trbušani' },
  { title: 'Kamenica' },
  { title: 'Mrčajevci' },
  { title: 'Mojsinje' },
  { title: 'Preljina' },
  { title: 'Bresnica' },
  { title: 'Gornja Trepca' },
  { title: 'Trnava' },
  { title: 'Ježevica' },
  { title: 'Zablaće' },
  { title: 'Slatina' },
  { title: 'Goričani' },
  { title: 'Guča' },
  { title: 'Goračići' },
  { title: 'Kaona' },
  { title: 'Kotraža' },
  { title: 'Lučani' },
  { title: 'Ovčar Banja' },
  { title: 'Markovica' },
  { title: 'Ivanjica' },
  { title: 'Bukovica' },
  { title: 'Prilicki Kiseljak' },
  { title: 'Brezova' },
  { title: 'Vionica' },
  { title: 'Medjurečje' },
  { title: 'Bratljevo' },
  { title: 'Kovilje' },
  { title: 'Kušići' },
  { title: 'Bele Vode' },
  { title: 'Gornji Milanovac' },
  { title: 'Brđani' },
  { title: 'Takovo' },
  { title: 'Bersici' },
  { title: 'Gornji Banjani' },
  { title: 'Brezna' },
  { title: 'Pranjani' },
  { title: 'Šilopaj' },
  { title: 'Boljkovci' },
  { title: 'Rudnik' },
  { title: 'Ugrinovci' },
  { title: 'Vračevšnica' },
  { title: 'Kragujevac' },
  { title: 'Grosnica' },
  { title: 'Ilićevo' },
  { title: 'Divostin' },
  { title: 'Bare' },
  { title: 'Gornja Sabanta' },
  { title: 'Erdec' },
  { title: 'Marsic' },
  { title: 'Rača Kragujevačka' },
  { title: 'Jovanovac' },
  { title: 'Malo Krcmare' },
  { title: 'Veliko Krčmare' },
  { title: 'Đurdjevo' },
  { title: 'Male Pčelice' },
  { title: 'Bukurovac' },
  { title: 'Lapovo' },
  { title: 'Lapovo Selo' },
  { title: 'Korman' },
  { title: 'Resnik' },
  { title: 'Badnjevac' },
  { title: 'Batočina' },
  { title: 'Brzan' },
  { title: 'Zirovnica' },
  { title: 'Gruza' },
  { title: 'Dragobraca' },
  { title: 'Guberevac' },
  { title: 'Knić' },
  { title: 'Bumbarevo Brdo' },
  { title: 'Toponica' },
  { title: 'Zabojnica' },
  { title: 'Arandjelovac' },
  { title: 'Bukovik' },
  { title: 'Ranilovic' },
  { title: 'Arandjelovac' },
  { title: 'Banja' },
  { title: 'Darosava' },
  { title: 'Vencane' },
  { title: 'Stojnik' },
  { title: 'Orašac' },
  { title: 'Jelovik' },
  { title: 'Topola' },
  { title: 'Belosavci' },
  { title: 'Natalinci' },
  { title: 'Donja Satornja' },
  { title: 'Jarmenovci' },
  { title: 'Desimirovac' },
  { title: 'Čumić' },
  { title: 'Stragari' },
  { title: 'Lužnice' },
  { title: 'Jagodina' },
  { title: 'Siokovac' },
  { title: 'Bagrdan' },
  { title: 'Jovac' },
  { title: 'Raševica' },
  { title: 'Potočac' },
  { title: 'Vojska' },
  { title: 'Subotica Kod Svilajnca' },
  { title: 'Svilajnac' },
  { title: 'Sedlare' },
  { title: 'Plažane' },
  { title: 'Despotovac' },
  { title: 'Stenjevac' },
  { title: 'Bobovo' },
  { title: 'Končarevo' },
  { title: 'Ribare' },
  { title: 'Glogovac' },
  { title: 'Veliki Popović' },
  { title: 'Medvedja' },
  { title: 'Kušiljevo' },
  { title: 'Krušar' },
  { title: 'Supska' },
  { title: 'Ćuprija' },
  { title: 'Senje' },
  { title: 'Senjski Rudnik' },
  { title: 'Ravna Reka' },
  { title: 'Mijatovac' },
  { title: 'Resavica' },
  { title: 'Bigrenica' },
  { title: 'Jasenovo' },
  { title: 'Kolare' },
  { title: 'Plana' },
  { title: 'Trešnjevica' },
  { title: 'Busilovac' },
  { title: 'Paraćin' },
  { title: 'Popovac' },
  { title: 'Donja Mutnica' },
  { title: 'Sikirica' },
  { title: 'Drenovac' },
  { title: 'Donje Vidovo' },
  { title: 'Svojnovo' },
  { title: 'Rekovac' },
  { title: 'Glavinci' },
  { title: 'Dragoševac' },
  { title: 'Belusic' },
  { title: 'Prevešt' },
  { title: 'Dragovo' },
  { title: 'Oparić' },
  { title: 'Miloševo' },
  { title: 'Strižilo' },
  { title: 'Majur' },
  { title: 'Novo Lanište' },
  { title: 'Dragocvet' },
  { title: 'Bunar' },
  { title: 'Kraljevo' },
  { title: 'Mataruška Banja' },
  { title: 'Samaila' },
  { title: 'Adrani' },
  { title: 'Lađevci' },
  { title: 'Roćevci' },
  { title: 'Vitanovac' },
  { title: 'Vitkovac' },
  { title: 'Sirča' },
  { title: 'Vrnjačka Banja' },
  { title: 'Ratina' },
  { title: 'Vrba' },
  { title: 'Podunavci' },
  { title: 'Novo Selo' },
  { title: 'Vrnjci' },
  { title: 'Čukojevac' },
  { title: 'Zica' },
  { title: 'Rudno' },
  { title: 'Novi Pazar' },
  { title: 'Dezeva' },
  { title: 'Lukare' },
  { title: 'Delimede' },
  { title: 'Sopoćani' },
  { title: 'Ribarice' },
  { title: 'Sjenica' },
  { title: 'Štavalj' },
  { title: 'Duga Poljana' },
  { title: 'Ugao' },
  { title: 'Tutin' },
  { title: 'Crkvine' },
  { title: 'Konarevo' },
  { title: 'Bogutovac' },
  { title: 'Ušće' },
  { title: 'Studenica' },
  { title: 'Baljevac Na Ibru' },
  { title: 'Jošanicka Banja' },
  { title: 'Brvenik' },
  { title: 'Raška' },
  { title: 'Rudnica' },
  { title: 'Kopaonik' },
  { title: 'Kruševac' },
  { title: 'Parunovac' },
  { title: 'Djunis' },
  { title: 'Zdravinje' },
  { title: 'Veliki Siljegovac' },
  { title: 'Ribarska Banja' },
  { title: 'Dvorane' },
  { title: 'Veliko Golovode' },
  { title: 'Čitluk' },
  { title: 'Velika Lomnica' },
  { title: 'Ćićevac' },
  { title: 'Stalać' },
  { title: 'Vitosevac' },
  { title: 'Pojate' },
  { title: 'Ražanj' },
  { title: 'Brus' },
  { title: 'Gornji Stepos' },
  { title: 'Kupci' },
  { title: 'Razbojna' },
  { title: 'Lepenac' },
  { title: 'Brzeće' },
  { title: 'Blazevo' },
  { title: 'Milentija' },
  { title: 'Zlatari' },
  { title: 'Grasevci' },
  { title: 'Aleksandrovac' },
  { title: 'Pepeljevac' },
  { title: 'Laćisled' },
  { title: 'Velika Vrbnica' },
  { title: 'Gornji Stupanj' },
  { title: 'Trnavci' },
  { title: 'Rataje' },
  { title: 'Ploča' },
  { title: 'Ples' },
  { title: 'Šljivovo' },
  { title: 'Trstenik' },
  { title: 'Stopanja' },
  { title: 'Počekovina' },
  { title: 'Medvedja' },
  { title: 'Velika Drenova' },
  { title: 'Milutovac' },
  { title: 'Globoder' },
  { title: 'Jasika' },
  { title: 'Bela Voda' },
  { title: 'Konjuh' },
  { title: 'Kukljin' },
  { title: 'Kaonik' },
  { title: 'Padež' },
  { title: 'Donji Krcin' },
  { title: 'Ribare' },
  { title: 'Varvarin' },
  { title: 'Bosnjane' },
  { title: 'Bačina' },
  { title: 'Obrez' },
  { title: 'Dasnica' },
  { title: 'Osreci' },
  { title: 'Kriva Reka' },
  { title: 'Brezovica' },
  { title: 'Gracanica' },
  { title: 'Kosovo Polje' },
  { title: 'Prilužje' },
  { title: 'Banjska' },
  { title: 'Sočanica' },
  { title: 'Leposavić' },
  { title: 'Lešak' },
  { title: 'Kosovska Mitrovica' },
  { title: 'Zvecan' },
  { title: 'Zubin Potok' },
  { title: 'Štrpce' },
  { title: 'Drajkovce' },
  { title: 'Pasjane' },
  { title: 'Ranilug' },
  { title: 'Brestac' },
];
