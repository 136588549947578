import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import { Box } from 'rebass';
import axios from 'axios';

import { MapObject } from '../../../types/types';
import FuzzySearch from 'react-fuzzy';
import { PageSection } from '../_/PageSection/PageSection.component';
import { theme } from '../../../layouts/main.layout';
import { GrayScale, mapWrap, PointsOfSalesWrapper, SkyEdgeBlue } from './storesMapSection.css';
import './storesMapSectionLegacy.css';
import { GATSBY_GMAPS_API_KEY } from '../../../configs/env';
import { AboveTitle, Text, Title } from '../../../global/new-global-components';
import { css } from '@emotion/core';
import { allSerbiaCities } from './AllSerbiaCities';

interface StoresMapSection {
  googleMapObjects: MapObject[];
  mapStyle?: 'SkyEdgeBlue' | 'GrayScale';
}

const mapStyleParser = {
  SkyEdgeBlue: SkyEdgeBlue,
  GrayScale: GrayScale,
};

const searchMapInputWrapper = css`
  position: absolute;
  top: 200px;
  z-index: 99999;
  border-radius: 20px;
  width: 1185px;
  left: 50%;
  padding: 40px 200px;
  padding-bottom: 80px;
  margin-left: -592.5px;
  background: linear-gradient(rgb(255, 255, 255) 55.76%, rgba(255, 255, 255, 0) 95%);

  @media screen and (max-width: 768px) {
    width: 400px;
    margin-left: -200px;
    padding: 20px;
  }
`;

export const StoresMapSection = ({ googleMapObjects, mapStyle }: StoresMapSection): ReactElement => {
  const [map, setMap] = useState(null);

  const mapInitialValues = {
    boundLat: 44.787197,
    boundLng: 20.457273,
    zoom: 10,
  };

  const [mapPosition, setMapPosition] = useState(mapInitialValues);

  const onSelectFuzzy = (selected: any): void => {
    axios
      .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${selected.title}+Serbia&key=${GATSBY_GMAPS_API_KEY}`)
      .then((response) => {
        const mapGeoData = response.data.results[0].geometry.location;
        selected.title === 'Novi Sad'
          ? setMapPosition({ boundLat: 45.2594947, boundLng: 19.786861, zoom: 12.5 })
          : setMapPosition({ boundLat: mapGeoData.lat, boundLng: mapGeoData.lng, zoom: 13 });
      });
  };

  const [selectedMapObject, setSelectedMapObject] = useState(null) as any;

  const { t } = useTranslation();

  const onSelect = (mapObject: MapObject): void => {
    setSelectedMapObject(mapObject);
    setMapPosition({
      ...mapPosition,
      boundLat: parseFloat(mapObject.attributes.lat),
      boundLng: parseFloat(mapObject.attributes.lng),
    });
  };

  const onLoad = useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds({
      lat: 44.787197,
      lng: 20.457273,
    });

    map.fitBounds(bounds);
    setMap(map);
  }, []);

  // TO-DO: for some reason setting zoom in onLoad function has no effect, we have to give map a second to load for zoom to take effect. Figure out how to fix this.
  useEffect(() => {
    if (map) {
      setTimeout(() => {
        map.setZoom(10);
      }, 1000);
    }
  }, [map]);

  return (
    <PointsOfSalesWrapper>
      <div style={{ padding: '120px 0px 20px 0' }}>
        <AboveTitle textTransform="normal">{t('packages_mapsSectionSubHeading')}</AboveTitle>
        <Title fontWeight="800">{t('packages_mapsSectionHeading')}</Title>
        <Text className="packages_mapsSectionText" fontSize="24px" margin="20px 0px" textAlign="center">
          {t('packages_mapsSectionText')}
        </Text>
        <div style={{ marginBottom: '25px' }}>
          <FuzzySearch
            className="fuzzy-root"
            selectedListItemStyle={{ background: 'white', borderTop: 'none', height: '36px' }}
            listItemStyle={{ borderTop: 'none' }}
            listWrapperStyle={{ height: '130px', marginTop: '25px', position: 'absolute', zIndex: 1 }}
            maxResults={4}
            list={allSerbiaCities}
            keys={['title']}
            placeholder={t('packages_mapsSectionPlaceholder')}
            onSelect={onSelectFuzzy}
          />
        </div>
      </div>
      <PageSection extendStyles={mapWrap} backgroundColor={theme.colors.white} paddingTop={1}>
        <Box id="map" style={{ position: 'relative', height: '980px' }}>
          <LoadScript googleMapsApiKey="AIzaSyAM59Hdm16dV-sTU_5GgDdSPpQxuE2lmE8">
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '980px',
              }}
              center={{ lat: mapPosition.boundLat, lng: mapPosition.boundLng }}
              zoom={mapPosition.zoom}
              options={{
                styles: mapStyleParser[mapStyle] || SkyEdgeBlue,
              }}
              onLoad={onLoad}
            >
              {googleMapObjects &&
                googleMapObjects.map((mapObject: MapObject) => {
                  let pinIcon = '/new-images/pin.png';
                  if (
                    mapObject.thumbnail &&
                    mapObject.thumbnail.data.attributes.path.includes('9shrIIdoMcsKSKwZMUygTEW6b43iYfEzwWxdrc5T')
                  ) {
                    pinIcon = '/img/pin-vulco.png';
                  }

                  return (
                    <Marker
                      key={mapObject.id}
                      position={{
                        lat: parseFloat(mapObject.attributes.lat),
                        lng: parseFloat(mapObject.attributes.lng),
                      }}
                      icon={{ url: pinIcon }}
                      onClick={(): void => onSelect(mapObject)}
                    />
                  );
                })}

              {selectedMapObject && (
                <InfoWindow
                  position={{
                    lat: parseFloat(selectedMapObject.attributes.lat),
                    lng: parseFloat(selectedMapObject.attributes.lng),
                  }}
                  onCloseClick={(): void => setSelectedMapObject(null)}
                >
                  <div style={{ lineHeight: 1.5 }}>
                    <p style={{ marginBottom: '12px', fontSize: '14px' }}>
                      <b>{selectedMapObject.attributes.name}</b>
                    </p>
                    <p>{`${t('map_City')} ${selectedMapObject.attributes.city}`}</p>
                    <p>{`${t('map_Address')} ${selectedMapObject.attributes.address}`}</p>
                    <p style={{ display: 'inline' }}>{`${t('map_Phone')} `}</p>
                    <a style={{ color: 'green' }} href={`tel:${selectedMapObject.attributes.phoneNumber}`}>
                      {selectedMapObject.attributes.phoneNumber}
                    </a>
                    <br />
                    <p style={{ display: 'inline' }}>{`${t('map_E-mail')} `}</p>
                    <a style={{ color: 'dodgerblue' }} href={`mailto:${selectedMapObject.attributes.email}`}>
                      {selectedMapObject.attributes.email}
                    </a>
                    <p>{`${t('map_WorkingHours')} ${
                      selectedMapObject.attributes.workingHours ? selectedMapObject.attributes.workingHours : 'N/A'
                    }`}</p>
                  </div>
                </InfoWindow>
              )}
            </GoogleMap>
          </LoadScript>
        </Box>
      </PageSection>
    </PointsOfSalesWrapper>
  );
};

export default StoresMapSection;
