import React from 'react';
import { useTranslation } from 'react-i18next';

import { VOZZiWebsitePage } from '../../types/VozziWebsitePage.type';
import { StoresMapSection } from '../../components/layout/StoresMapSection/StoresMapSection';
import { StandardPageLayout } from '../../layouts/StandardPage.layout';
import { LanguageGuard } from '../../components/layout/LanguageGuard/LanguageGuard';
import { Advantages } from './sections/advantages/Advantages.section';
import { Intro } from './sections/intro/Intro.section';
import { Towing } from './sections/towing/towing.section';
import { Service } from './sections/service/service.section';
import { Moto } from './sections/moto/moto.section';
import { Faq } from './sections/faq/faq.section';
import ContactInfo from '../../components/layout/contact/ContactInfo.component';

const RoadsideAssistancePage: VOZZiWebsitePage = (props) => {
  const { t } = useTranslation();

  const metaOgImg = `/img/meta-img/road-assistance-meta-og-2-0-new.jpeg`;

  const meta = {
    title: t('meta_RoadsideAssistanceTitle'),
    description: t('meta_RoadsideAssistanceDescription'),
  };

  const og = {
    image: metaOgImg,
  };

  return (
    <StandardPageLayout
      mainHeading={t('ra_MainHeading')}
      subText={t('ra_MainSubText')}
      subTextTwo={t('ra_MainSubTextTwo')}
      backgroundImage="bg/roadside-assistance@3x.jpg"
      backgroundImageMobile="bg/roadside-assistance-mobile@3x.jpg"
      buttonText={t('btn_SeePackages')}
      to={t('link_webShop')}
      meta={meta}
      og={og}
    >
      <Intro />
      <Advantages />
      <Towing />
      <Service />
      <Moto />
      <Faq />

      {/* <LanguageGuard visible={[{ country: 'rs' }, { country: 'me' }]}>
        <Tutorials />
      </LanguageGuard> */}
      <LanguageGuard visible={[{ country: 'rs' }]}>
        <StoresMapSection googleMapObjects={props.pageContext.googleMapObjects} />
      </LanguageGuard>

      <ContactInfo />
    </StandardPageLayout>
  );
};

export default RoadsideAssistancePage;
