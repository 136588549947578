import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Box, Image, Text } from 'rebass';
import { css } from '@emotion/core';

interface AdvantagesProps {
  imgUrl: string;
  heading: string;
  text: string;
}

const advantagesBoxCss = css`
  flex-basis: 24%;
  @media screen and (min-width: 600px) and (max-width: 830px) {
    width: 65%;
  }
`;

const styleHeading = css`
  padding: 16px 15px;
  white-space: pre-line;
  @media (max-width: 1350px) {
    padding: 16px 0px;
  }
`;

const styleImg = css`
  height: auto;
`;

const AdvantagesBox: React.FC<AdvantagesProps> = ({ imgUrl, heading, text }) => {
  const data = useStaticQuery(
    graphql`
      query {
        backgroundImage: allFile(filter: { relativeDirectory: { eq: "advantages" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  const isItLastImage = imgUrl == '/img/advantages-3-eng@3x.png';

  const image = data.backgroundImage.edges.find(({ node }: any) => node.relativePath === imgUrl);

  return (
    <Box css={advantagesBoxCss} mb={{ _: 5, md: 0 }}>
      {image ? (
        <Img fluid={image.node.childImageSharp.fluid} css={styleImg} />
      ) : (
        <Image src={imgUrl} style={isItLastImage ? { marginTop: '25px' } : { marginTop: '0px' }} />
      )}

      <Text as="h3" fontSize={{ _: 3, md: 5 }} css={styleHeading}>
        {heading}
      </Text>
      <Text textAlign="center" px={{ _: 3, md: 0 }} width="101%">
        {text}
      </Text>
    </Box>
  );
};

export default AdvantagesBox;
