import React from 'react';
import { css } from '@emotion/core';
import { theme } from '../../../layouts/main.layout';
import { Box, Flex, Text } from 'rebass';

interface SectionHeaderProps {
  title: string;
  subTitle?: string;
  underline?: boolean;
  underlineWithArrow?: boolean;
  alignItems?: string;
  mt?: any;
  mb?: any;
}

const headerTitle = css`
  font-family: ${theme.fonts.heading};
  text-align: center;
  font-size: ${theme.fontSizes[6]}px;
  font-weight: ${theme.fontWeights.heading};
`;

const headerSubTitle = css`
  color: ${theme.colors.primaryTextGray};
  font-family: ${theme.fonts.body};
  text-align: center;
  max-width: 600px;
`;

const UnderlineImg: React.FC = () => (
  <Box mt={1}>
    <svg xmlns="http://www.w3.org/2000/svg" width="182" height="3" viewBox="0 0 182 3">
      <path fill="#F47E20" fillRule="evenodd" d="M559 111.747H741V113.747H559z" transform="translate(-559 -111)" />
    </svg>
  </Box>
);

const UnderlineWithArrowImg: React.FC = () => (
  <Box mt={2}>
    <svg xmlns="http://www.w3.org/2000/svg" width="182" height="16" viewBox="0 0 182 16">
      <g fill="#F47E20" fillRule="evenodd">
        <path d="M0 7.247H70V9.247H0zM112 7.247H182V9.247H112zM100.5 9.862V1.388c0-.684-.732-1.11-1.315-.766l-7.245 4.27c-.272.16-.608.16-.88 0L83.815.621c-.583-.344-1.315.082-1.315.766v8.474c0 .317.167.61.437.768l8.125 4.751c.271.159.605.159.875 0l8.126-4.751c.27-.159.437-.451.437-.768" />
      </g>
    </svg>
  </Box>
);

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subTitle, underline, underlineWithArrow, alignItems, mt, mb }) => {
  return (
    <Flex alignItems={alignItems || 'center'} flexDirection="column" mt={mt || 5} mb={mb || 3} ml={{ _: '9px', md: '0px' }}>
      <Text as="h2" css={headerTitle}>
        {title}
      </Text>

      {subTitle ? (
        <Text mt={2} css={headerSubTitle}>
          {subTitle}
        </Text>
      ) : null}

      {underline ? <UnderlineImg /> : null}

      {underlineWithArrow ? <UnderlineWithArrowImg /> : null}
    </Flex>
  );
};

export default SectionHeader;
