import { css } from '@emotion/core';
import { theme } from '../../../../../layouts/main.layout';

export const faq = css`
  .accordion {
    border: none;

    &__button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      background-color: white;
      padding: 18px 0;

      &:focus {
        outline: none;
      }

      &:before {
        display: block;
        content: '';
        background-image: url('/img/arrow-orange.svg');
        background-repeat: no-repeat;
        transform: rotate(180deg);
        height: 24px;
        width: 24px;
        border: none;
        margin-left: ${theme.space[3]}px;
        margin-right: ${theme.space[0]}px;
        transform: rotate(90deg);
        transition: transform 1s linear;
      }

      &:focus:before {
        transform: rotate(270deg);
        transition: transform 1s linear;
      }
    }

    h4 {
      flex: 1;
      font-weight: ${theme.fontWeights.bold};
      font-size: 20px;
    }

    &__panel {
      padding-top: ${theme.space[0]}px;
      padding-left: ${theme.space[0]}px;
    }
  }

  a {
    color: ${theme.colors.vozziVividOrange};
    font-style: italic;

    &:hover {
      text-decoration: none;
    }
  }

  [data-reach-tabs] {
    display: flex;
    background-color: ${theme.colors.white};
  }

  [data-reach-tab] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.gray};
    height: 80px;
    text-align: start;
    font-weight: ${theme.fontWeights.bold};

    &:first-of-type {
      border-top: 1px solid ${theme.colors.gray};
    }

    &:hover,
    &:active,
    &:focus {
      color: ${theme.colors.vozziVividOrange};
      outline: none;

      .arrow {
        background-image: url(/img/arrow-orange.svg);
      }
    }
  }

  [data-reach-tab][data-selected] {
    color: ${theme.colors.vozziVividOrange};

    .arrow {
      background-image: url(/img/arrow-orange.svg);
    }
  }

  [data-reach-tab-panel] {
    max-width: 600px;
    padding-left: 80px;
  }

  [data-reach-tab-list] {
    display: flex;
    flex-direction: column;
    max-width: 600px;
  }

  .arrow {
    margin-left: 200px;
    padding-left: 15px;
    background-image: url(/img/arrow-gray.svg);
    background-repeat: no-repeat;
    width: 22px;
    height: 24px;
  }

  @media screen and (max-width: 832px) {
    .arrow {
      display: none;
    }
  }
`;

export const accordionItem = css`
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &:first-of-type {
    border-top: none;
  }

  a {
    color: ${theme.colors.vozziVividOrange};
  }
`;
