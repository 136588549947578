import React from 'react';
import { Text } from 'rebass';

import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';
import { useTranslation } from 'react-i18next';

export const Intro: React.FC = () => {
  const { t } = useTranslation();
  return (
    <PageSection>
      <SectionHeader title={t('ra_IntroHeading')} mt={{ _: 4, sm: 5 }} mb={4} />
      <Text textAlign="center">
        <Text>{t('ra_IntroTextOne')}</Text>
        <Text>{t('ra_IntroTextTwo')}</Text>
        <Text>{t('ra_IntroTextThree')}</Text>
      </Text>
    </PageSection>
  );
};
