import { css } from '@emotion/core';

export const service = css`
  p {
    padding: 5px 0;
  }

  .service-image {
    width: 100%;
    height: 0;
    padding-bottom: 158%;
  }

  @media screen and (max-width: 640px) {
    .service-image {
      padding-bottom: 102%;
      margin-bottom: 15px;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1024px) {
    .service-image {
      padding-bottom: 62%;
      float: left;
      width: 40%;
      margin: 32px 32px 20px 32px;
    }
    .mobile-position {
      display: inherit;
    }
    ul {
      position: relative;
      left: 5%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .service-image {
      float: left;
      padding-bottom: 49%;
      clear: both;
      width: 32%;
      margin: 32px 32px 20px 32px;
    }
    .mobile-position {
      display: inherit;
    }
    ul {
      position: relative;
      left: 4%;
    }
  }
`;
