import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';
import { Box, Flex, Text } from 'rebass';
import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import { theme } from '../../../../layouts/main.layout';
import { service } from './css/service.css';
import { useTranslation } from 'react-i18next';
import StoreBadgeIcons from '../../../../components/layout/StoreBadgeIcons/StoreBadgeIcons';

export const Service: React.FC = () => {
  const { t } = useTranslation();

  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "service-mobile@3x.png" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(relativePath: { eq: "service@3x.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const sources = [
    {
      ...mobileImage.childImageSharp.fluid,
      media: `(max-width: 640px)`,
    },

    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ];

  return (
    <PageSection extendStyles={service}>
      <Flex
        css={{
          boxShadow: theme.colors.boxShadow,
          borderRadius: '6px',
        }}
        backgroundColor={theme.colors.white}
        mt={5}
        flexDirection={{ _: 'column', sm: 'row' }}
        className="mobile-position"
      >
        <Box flex={'1 1 33%'}>
          <Img fluid={sources} className="service-image" />
        </Box>

        <Box flex={{ _: '0 1 64%', xl: '0 1 74%' }} px={{ _: 2, sm: 4 }} py={{ _: 4, xl: 0 }} alignSelf="center">
          <SectionHeader title={t('ra_ServiceHeading')} alignItems="start" mt={{ _: 0 }} />
          <Text as="p" fontWeight={theme.fontWeights.bold}>
            {t('ra_ServiceTitleOne')}
          </Text>
          <Box as="ul" ml={2}>
            <Text as="li">{t('ra_ServiceTextOne')}</Text>
            <Text as="li">{t('ra_ServiceTextOne/2')}</Text>
            <Text as="li">{t('ra_ServiceTextOne/3')}</Text>
            <Text as="li">{t('ra_ServiceTextOne/4')}</Text>
          </Box>
          <Text as="p" fontWeight={theme.fontWeights.bold}>
            {t('ra_ServiceTitleTwo')}
          </Text>
          <Text as="p">{t('ra_ServiceTextTwo')}</Text>
          <Text as="p" fontWeight={theme.fontWeights.bold}>
            {t('ra_ServiceTitleThree')}
          </Text>
          <Text as="p">{t('ra_ServiceTextThree')}</Text>
          <Flex mt={3}>
            <StoreBadgeIcons customFlexWrapper={true} />
          </Flex>
        </Box>
      </Flex>
    </PageSection>
  );
};
