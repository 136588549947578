import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Box, Flex, Text } from 'rebass';
import { Trans, useTranslation } from 'react-i18next';
import Img from 'gatsby-image';

import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';
import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import { theme } from '../../../../layouts/main.layout';
import { VozziPrimaryButton } from '../../../../components/layout/_/button.component';
import { VideoModal } from '../../../../components/layout/VideoModal/VideoModal';
import { LanguageGuard } from '../../../../components/layout/LanguageGuard/LanguageGuard';

import { towing } from './css/towing.css';

export const Towing: React.FC = () => {
  const { t } = useTranslation();

  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "towing-mobile@3x.png" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(relativePath: { eq: "towing@3x.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const sources = [
    {
      ...mobileImage.childImageSharp.fluid,
      media: `(max-width: 640px)`,
    },

    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');

  const hideModal = () => {
    setIsOpen(false);
  };

  const openModal = (src: string) => {
    setVideoSrc(src);
    setIsOpen(true);
  };

  return (
    <PageSection extendStyles={towing}>
      <Flex
        css={{
          boxShadow: theme.colors.boxShadow,
          borderRadius: '6px',
        }}
        backgroundColor={theme.colors.white}
        mt={5}
        flexDirection={{ _: 'column', sm: 'row' }}
        className="mobile-position"
      >
        <VideoModal show={isOpen} onHide={hideModal} videoSrc={videoSrc} />
        <Box flex={'1 1 33%'}>
          <Img fluid={sources} className="towing-image" />
        </Box>

        <Box flex={{ _: '0 1 64%', xl: '0 1 74%' }} px={{ _: 2, sm: 4 }} py={{ _: 4, xl: 1 }} alignSelf="center">
          <SectionHeader title={t('ra_TowingHeading')} alignItems="start" mt={{ _: 0 }} />

          <Text as="p" fontWeight={theme.fontWeights.bold}>
            {t('ra_TowingTitleOne')}
          </Text>

          <Text as="p">{t('ra_TowingTextOne')}</Text>

          <Text as="p" fontWeight={theme.fontWeights.bold}>
            {t('ra_TowingTitleTwo')}
          </Text>

          <Text as="p">{t('ra_TowingTextTwo')}</Text>

          <LanguageGuard hidden={[{ country: 'eu' }, { country: 'ro' }]}>
            <Link to={t('link_webShop')}>
              <VozziPrimaryButton my={2}>{t('btn_SeePackages')}</VozziPrimaryButton>
            </Link>
          </LanguageGuard>

          <Text as="p" fontWeight={theme.fontWeights.bold}>
            {t('ra_TowingTitleThree')}
          </Text>

          <Trans i18nKey={'ra_TowingTextThree'}>
            part1<span onClick={() => openModal('/video/accident-flow-user-MPEG-4-v2.mp4')}>part2</span>
          </Trans>
        </Box>
      </Flex>
    </PageSection>
  );
};
