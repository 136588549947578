import React from 'react';
import { Box, Flex } from 'rebass';

import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';

import AdvantagesBox from './advantages-box/advantagesBox';
import { useTranslation } from 'react-i18next';

export const Advantages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageSection>
      <SectionHeader title={t('ra_AdvantagesHeading')} underlineWithArrow mt={{ _: 4, sm: 6 }} mb={4} />
      <Box>
        <Flex
          textAlign="center"
          justifyContent="space-around"
          alignItems={{ _: 'center', md: 'start' }}
          flexDirection={{ _: 'column', md: 'row' }}
        >
          <>
            <AdvantagesBox imgUrl={t('advantages_one_src')} heading={t('ra_AdvantagesTitleOne')} text={t('ra_AdvantagesTextOne')} />

            <AdvantagesBox imgUrl={t('advantages_two_src')} heading={t('ra_AdvantagesTitleTwo')} text={t('ra_AdvantagesTextTwo')} />

            <AdvantagesBox imgUrl={t('advantages_three_src')} heading={t('ra_AdvantagesTitleThree')} text={t('ra_AdvantagesTextThree')} />
          </>
        </Flex>
      </Box>
    </PageSection>
  );
};
