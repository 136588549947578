import React from 'react';
import { useTranslation } from 'react-i18next';

import 'react-accessible-accordion/dist/fancy-example.css';

const ContactInfo = (): React.ReactElement => {
  const { t } = useTranslation();
  return <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Contact section</div>;
};

export default ContactInfo;
