import { css } from '@emotion/core';
import { theme } from '../../../../../layouts/main.layout';

export const moto = css`
  p {
    padding: 5px 0;
  }

  .moto-image {
    width: 100%;
    height: 0;
    padding-bottom: 97%;
  }

  strong {
    font-weight: ${theme.fontWeights.bold};
  }

  @media screen and (max-width: 640px) {
    .moto-image {
      padding-bottom: 102%;
      margin-bottom: 15px;
    }
  }

  @media screen and (min-width: 640px) and (max-width: 1024px) {
    .moto-image {
      padding-bottom: 47%;
      float: left;
      width: 40%;
      margin: 32px 32px 20px 32px;
    }

    .mobile-position {
      display: inherit;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1215px) {
    .moto-image {
      float: left;
      padding-bottom: 30%;
      clear: both;
      width: 32%;
      margin: 32px 32px 20px 32px;
    }

    .mobile-position {
      display: inherit;
    }
  }
`;
