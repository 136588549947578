import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import SectionHeader from '../../../../components/layout/SectionHeader/SectionHeader.component';
import { Box, Flex, Text } from 'rebass';
import { PageSection } from '../../../../components/layout/_/PageSection/PageSection.component';
import { theme } from '../../../../layouts/main.layout';
import { moto } from './css/moto.css';
import { Trans, useTranslation } from 'react-i18next';

export const Moto: React.FC = () => {
  const { t } = useTranslation();
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "moto-mobile@3x.png" }) {
          childImageSharp {
            fluid(maxWidth: 640, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        desktopImage: file(relativePath: { eq: "moto@3x.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const sources = [
    {
      ...mobileImage.childImageSharp.fluid,
      media: `(max-width: 640px)`,
    },

    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 640px)`,
    },
  ];

  return (
    <PageSection extendStyles={moto} paddingBottom={80}>
      <Flex
        css={{
          boxShadow: theme.colors.boxShadow,
          borderRadius: '6px',
        }}
        backgroundColor={theme.colors.white}
        mt={5}
        flexDirection={{ _: 'column', sm: 'row' }}
        className="mobile-position"
      >
        <Box flex={'1 1 33%'}>
          <Img fluid={sources} className="moto-image" />
        </Box>

        <Box flex={{ _: '0 1 64%', xl: '0 1 74%' }} px={{ _: 2, sm: 4 }} py={{ _: 4, xl: 0 }} alignSelf="center">
          <SectionHeader title={t('ra_MotoHeading')} alignItems="start" mt={{ _: 0 }} />
          <Text as="p">
            <Trans i18nKey="ra_MotoTextOne">
              text<strong></strong>
            </Trans>
          </Text>
          <Text as="p">
            <Trans i18nKey="ra_MotoTextTwo">
              text<strong></strong>
            </Trans>
          </Text>
          <Text as="p">
            <Trans i18nKey="ra_MotoTextThree" as="p">
              text<strong></strong>
            </Trans>
          </Text>
          <Text as="p">
            <Trans i18nKey="ra_MotoTextFour" as="p">
              text<strong></strong>
            </Trans>
          </Text>
        </Box>
      </Flex>
    </PageSection>
  );
};
